<template>
    <div>
        <div class="row">
            <div class="col-12">
                <card class="xss-card-container">
                    <base-alert type="warning" v-if="!is_payload_setup">
                        <i class="tim-icons icon-alert-circle-exc"></i> <strong>WARNING</strong> These settings mean that your XSS Hunter subdomain is <i>not</i> currently serving any payload!
                    </base-alert>
                    <div class="row pl-4 pr-4 p-2" style="display: block;">
                        <div>
                            <h1><i class="fas fa-cogs"></i> Account Settings</h1>
                        </div>
                        <card>
                            <h4 class="card-title">Forwarding URL <span style="color: red" v-if="!forward_url || forward_url == '' || static_javascript != ''"><i>(Not Enabled)</i></span></h4>
                            <h6 class="card-subtitle mb-2 text-muted">
                                Where your payload subdomain (<code>*.xss.ht</code>) should forward to now that XSS Hunter is deprecated.
                            </h6>
                            <p class="card-text">
                                <base-input :class="!is_valid_forwarding_url && 'has-danger'" v-model="forward_url" type="text" placeholder="..."></base-input>
                            </p>

                            <base-alert type="default">
                                Looking for a new XSS Hunter to forward your subdomain to? Try <a target="_blank" href="https://github.com/mandatoryprogrammer/xsshunter-express">hosting your own</a> or using another hosted provider.
                            </base-alert>
                        </card>
                        <card>
                            <h4 class="card-title">Static Payload<span style="color: red" v-if="!static_javascript || static_javascript == ''"><i>(Not Enabled)</i></span></h4>
                            <h6 class="card-subtitle mb-2 text-muted">
                                A static JavaScript payload to return for your payload subdomain (<code>*.xss.ht</code>)
                            </h6>
                            <p class="card-text">
                                <div class="col">
                                    <base-input>
                                        <textarea class="form-control" type="text" placeholder="/robots.txt" v-model="static_javascript" />
                                        </base-input>
                                </div>
                            </p>
                        </card>
                        <card>
                            <h4 class="card-title">Email</h4>
                            <h6 class="card-subtitle mb-2 text-muted">
                                The email address for your XSS Hunter account.
                            </h6>
                            <p class="card-text">
                                <base-input v-model="email" type="text" placeholder="..." ></base-input>
                            </p>
                        </card>
                        <card>
                            <h4 class="card-title">Password</h4>
                            <h6 class="card-subtitle mb-2 text-muted">
                                The password to your XSS Hunter account.
                            </h6>
                            <p class="card-text">
                                <base-input v-model="password" type="password" placeholder="..." ></base-input>
                            </p>
                        </card>
                        <card>
                            <h4 class="card-title">Subdomain</h4>
                            <h6 class="card-subtitle mb-2 text-muted">
                                Your payload subdomain (<code>*.xss.ht</code>), this can not be changed.
                            </h6>
                            <p class="card-text">
                                <base-input v-bind:value="subdomain + '.xss.ht'" type="text" placeholder="..." readonly></base-input>
                            </p>
                        </card>
                        <fieldset v-if="form_submittable">
                            <base-button type="primary" v-on:click="save_settings">
                                <i class="far fa-save"></i> Save Settings
                            </base-button>
                        </fieldset>
                        <fieldset disabled v-if="!form_submittable">
                            <base-button type="primary" v-on:click="save_settings">
                                <i class="far fa-save"></i> Save Settings
                            </base-button>
                            <br />
                            <span style="color: red"><i>Cannot save these settings. Check your input values.</i></span>
                        </fieldset>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import config from '@/config';
import Vue from "vue";
import api_request from '@/libs/api.js';
import router from "@/router/index";
import utils from '@/libs/utils';
import BaseAlert from '@/components/BaseAlert.vue';

const MAGIC_SUBDOMAIN_PREFIX = `REDIRECT `;
const user_data_keys = [
    'forward_url',
    'static_javascript',
    'email',
    'password',
    'subdomain',
    'payload'
];

export default {
    data() {
        return {
            base_api_path: '',

            forward_url: '',
            static_javascript: '',
            email: '',
            password: '',
            subdomain: '',
            payload: '',

            // To compare against changed values
            initial_user_data: {},
        }
    },
    watch: {},
    methods: {
        populate_settings: async function() {
            const result = await api_request.get_user_data();
            const user_data = result.data;

            this.password = '';

            this.payload = user_data.payload;

            // Forward URL vs payload logic parse string to find out
            if(user_data.payload && user_data.payload.startsWith(MAGIC_SUBDOMAIN_PREFIX)) {
                this.forward_url = user_data.payload.replace(MAGIC_SUBDOMAIN_PREFIX, '');
            } else if(user_data.payload) {
                this.static_javascript = user_data.payload;
            }

            this.email = user_data.email;
            this.subdomain = user_data.subdomain;

            // Keep copy of originals to detect changes
            user_data_keys.map(key => {
                this.initial_user_data[key] = this[key];
            });
        },
        save_settings: async function() {
            var api_promises = [];
            // Check what settings have changed and update them
            if(this.email != this.initial_user_data.email) {
                api_promises.push(
                    api_request.update_email(
                        this.email
                    )
                );
            }

            if(this.password != this.initial_user_data.password) {
                api_promises.push(
                    api_request.update_password(
                        this.password
                    )
                );
            }

            // Forwarding URL vs payload logic
            var payload = '';

            if(this.forward_url && this.is_valid_forwarding_url) {
                payload = `${MAGIC_SUBDOMAIN_PREFIX}${this.forward_url}`;
            }

            if(this.static_javascript && this.static_javascript !== '') {
                payload = this.static_javascript;
            }

            if(payload != this.initial_user_data.payload) {
                api_promises.push(
                    api_request.update_payload(
                        payload
                    )
                );
            }

            await Promise.all(api_promises);

            // Refresh settings values from save 
            await this.populate_settings();

            toastr.success('Settings saved successfully.');
        },
    },
    computed: {
        is_valid_forwarding_url: function() {
            if(this.forward_url == '') {
                return true;
            }
            return (
                this.forward_url.startsWith('https://') ||
                this.forward_url.startsWith('http://')
            )
        },
        form_submittable: function() {
            return (
                this.is_valid_forwarding_url
            );
        },
        is_payload_setup: function() {
            return (
                (this.is_valid_forwarding_url && this.forward_url != '' ) ||
                (this.static_javascript != '')
            )
        }
    },
    components: {
        BaseAlert,
    },
    async mounted() {
        // For debugging
        window.app = this;

        // For rendering
        this.base_api_path = api_request.BASE_API_PATH;

        this.populate_settings();
    },
    beforeDestroy() {}
};
</script>
<style>
.dropdown-item {
    font-size: 16px !important;
}
</style>